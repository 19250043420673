.chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #18ba60;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 4;
  }
  .chat-button .fas {
    margin-right: 8px;
  }
  
  .chat-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 360px;
    height: 640px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    z-index: 234232;
  }
  
  .chat-popup-mobile {
    width: 90%;
    height: 90%;
    bottom: 10px;
    right: 10px;
    z-index: 234233;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #D32F2F;
    color: white;
  }
  
  .close-button {
    cursor: pointer;
    z-index: 5;
  }
  
  .chat-body {
    flex: 1;
    overflow-y: auto;
  }

  .lead-wait {
    text-align: center;
    vertical-align: middle;
    height: 100%;
  }
  .lead-wait-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #D32F2F;
    color: white;
  }
  .lead-wait-section{
    vertical-align: middle;
    height: 100%;
    margin-top: 100px;
  }
  .lead-wait-icon {
    align-items: center;
    align-content: center;
  }
  .animated-icon {
    width: 100px;
    height: 100px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .full-height-div {
    height: calc(var(--vh, 1vh) * 100);
  }
  .full-height-min-vh {
    min-height: 100vh; /* Ensures minimum height is full viewport height */
    background-color: lightcoral;
  }


  