.cs-message--incoming .cs-message__content{
    background-color: #e9ecef;
  }
  .cs-message--outgoing .cs-message__content{
    background-color: #ecefff;
  }
  .cs-conversation-header{
    color: #fff;
    background-color: #f9004d;
  }
.cs-conversation-header__content .cs-conversation-header__user-name {
  background-color: inherit;
  color: inherit;
}
.cs-button {
    color: #fff;
}
.cs-button--send{
    color: #18ba60;
}
.cs-message-input__content-editor-wrapper {
    background-color: #f8f9fa;
}
.cs-message-input__content-editor-wrapper .cs-message-input__content-editor {
    background-color: #f8f9fa;
}